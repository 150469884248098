
































































































import useUser from '@/use/user'
import { computed, defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root })

    const state = reactive({
      model: false
    })

    const isDisplayed = computed(() =>
      root.$route.name != 'panel.chat.view' &&
      root.$route.name != 'panel.contact.user' &&
      root.$route.name != 'panel.contact.service'
    )

    return { hasAccessTo, state, isDisplayed }
  }
})
