import { computed, SetupContext } from "@vue/composition-api";

export default function useUser({ root }: Partial<SetupContext>) {
  const token = computed(() => root?.$store.getters["user/getToken"]);

  const currentUser = computed(() => root?.$store.getters["user/getData"]);
  const currentCompany = computed(
    () => root?.$store.getters["user/getCompany"]
  );

  const getRoleName = (name: string) => {
    switch (name) {
      case "driver":
        return "Kierowca";
      case "customer":
        return "Klient";
      case "partner":
        return "Partner";
      case "employee":
        return "Pracownik";
      case "admin":
        return "Administrator";
    }
  };

  const hasAccessTo = computed<(role: string) => boolean>(
    () => root?.$store.getters["user/hasAccessTo"]
  );
  const hasManyCompanies = computed<() => boolean>(
    () => root?.$store.getters["user/hasManyCompanies"]
  );

  return {
    token,
    currentUser,
    currentCompany,
    getRoleName,
    hasAccessTo,
    hasManyCompanies,
  };
}
