var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDisplayed)?_c('v-speed-dial',{staticClass:"o-speed-dial",attrs:{"right":"","bottom":"","direction":"top","transition":"slide-y-reverse-transition","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"green","dark":"","fab":""},model:{value:(_vm.state.model),callback:function ($$v) {_vm.$set(_vm.state, "model", $$v)},expression:"state.model"}},[(_vm.state.model)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,false,1968511710),model:{value:(_vm.state.model),callback:function ($$v) {_vm.$set(_vm.state, "model", $$v)},expression:"state.model"}},[(_vm.hasAccessTo('admin'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"primary","to":{ name: 'panel.company.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building")])],1)]}}],null,false,2782755826)},[_c('span',[_vm._v("Dodaj firmę")])]):_vm._e(),(_vm.hasAccessTo('admin'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"primary","to":{ name: 'panel.user.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-tie")])],1)]}}],null,false,312386059)},[_c('span',[_vm._v("Dodaj użytkownika")])]):_vm._e(),(_vm.hasAccessTo('employee'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"primary","to":{ name: 'panel.alert.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alert")])],1)]}}],null,false,881845678)},[_c('span',[_vm._v("Dodaj alert")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"primary","to":{ name: 'panel.vehicle.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-car")])],1)]}}],null,false,1400057926)},[_c('span',[_vm._v("Dodaj pojazd")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary","to":{ name: 'panel.chat.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-forum")])],1)]}}],null,false,911115929)},[_c('span',[_vm._v("Rozpocznij chat")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary","to":{ name: 'panel.contact.user' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,false,2746154520)},[_c('span',[_vm._v("Kontakt")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"primary","to":{ name: 'panel.request.add' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-face-agent")])],1)]}}],null,false,1002524138)},[_c('span',[_vm._v("Dodaj zgłoszenie")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }